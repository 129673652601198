<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
</style>

<script>
//import axios from 'axios'
import { AUTH_SUCCESS, AUTH_SIGNOUT } from './store/actions/auth'
import duppla from '@/duppla'

export default {
  name: 'app',
  metaInfo: {
    title: '',
    titleTemplate: '%s' + process.env.VUE_APP_TITLE
  },
  created: function () {
    window.addEventListener('storage', this.syncLogout)

    let accessToken = this.$store.state.auth.accessToken
    if (accessToken) {
      this.$store.dispatch(AUTH_SUCCESS, accessToken).then(() => {
        //this.$router.push({ name: 'questionnaire-result'})
      })
    }

    // Heap Analytics script
    (function() {
      window.heapReadyCb = window.heapReadyCb || [];
      window.heap = window.heap || [];
      heap.load = function(e, t) {
        window.heap.envId = e;
        window.heap.clientConfig = t = t || {};
        window.heap.clientConfig.shouldFetchServerConfig = !1;
        var a = document.createElement("script");
        a.type = "text/javascript";
        a.async = !0;
        a.src = "https://cdn.us.heap-api.com/config/" + e + "/heap_config.js";
        var r = document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(a, r);
        var n = ["init", "startTracking", "stopTracking", "track", "resetIdentity", "identify", "getSessionId", "getUserId", "getIdentity", "addUserProperties", "addEventProperties", "removeEventProperty", "clearEventProperties", "addAccountProperties", "addAdapter", "addTransformer", "addTransformerFn", "onReady", "addPageviewProperties", "removePageviewProperty", "clearPageviewProperties", "trackPageview"];
        var i = function(e) {
          return function() {
            var t = Array.prototype.slice.call(arguments, 0);
            window.heapReadyCb.push({
              name: e,
              fn: function() {
                heap[e] && heap[e].apply(heap, t)
              }
            })
          }
        };
        for (var p = 0; p < n.length; p++) heap[n[p]] = i(n[p])
      };
      heap.load(process.env.VUE_APP_HEAP_ID);
    })();
  }
}
</script>
